import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M1670 4262 c0 -5 -115 -534 -255 -1177 -140 -642 -255 -1180 -255
-1194 0 -31 38 -97 49 -85 4 5 69 295 145 644 153 702 373 1712 381 1748 l5
22 778 0 777 0 -35 23 -35 22 -777 3 c-428 1 -778 -1 -778 -6z"/>
<path d="M1815 4118 c-2 -7 -70 -314 -150 -683 -80 -368 -161 -740 -180 -825
-184 -842 -186 -852 -175 -884 11 -34 59 -85 97 -105 15 -8 244 -11 796 -11
l774 0 67 308 c37 169 69 315 72 324 6 19 -10 30 -335 232 -112 70 -207 124
-210 119 -4 -4 -32 -119 -62 -255 l-54 -248 -227 0 c-126 0 -228 3 -228 6 0 8
330 1516 336 1537 5 15 25 17 225 17 173 0 221 -3 225 -13 3 -8 -7 -66 -21
-131 -14 -65 -25 -124 -25 -131 0 -7 46 -42 103 -78 56 -35 178 -112 271 -171
93 -59 170 -106 171 -104 8 10 205 930 205 957 0 32 -47 102 -87 128 -25 17
-78 18 -805 21 -623 2 -779 0 -783 -10z"/>
<path d="M2596 3528 c-4 -17 1 -29 22 -45 35 -29 38 -29 46 5 4 15 9 35 12 45
4 14 -1 17 -34 17 -33 0 -41 -4 -46 -22z"/>
<path d="M2376 2517 c-27 -122 -54 -237 -58 -254 l-9 -33 -94 0 c-95 0 -95 0
-95 -25 l0 -25 129 0 129 0 20 88 c11 48 37 165 58 260 l38 174 -24 19 c-14
10 -29 19 -35 19 -5 0 -32 -100 -59 -223z"/>
<path d="M1311 1233 c-30 -117 -63 -293 -58 -307 5 -13 24 -16 116 -16 60 0
112 4 115 8 3 5 8 27 12 49 6 38 4 41 -29 62 -42 26 -47 26 -47 2 0 -37 -12
-51 -42 -51 -30 0 -30 1 -25 38 4 20 14 70 23 110 16 72 16 72 49 72 27 0 32
-3 28 -19 -3 -13 6 -25 32 -41 20 -12 38 -20 40 -18 8 8 34 132 29 140 -3 4
-56 8 -119 8 l-114 0 -10 -37z"/>
<path d="M1646 1253 c-21 -80 -67 -316 -62 -328 4 -12 28 -15 119 -15 l113 0
37 171 c20 94 34 175 31 180 -3 5 -56 9 -119 9 -100 0 -114 -2 -119 -17z m141
-70 c-3 -10 -11 -43 -17 -73 -28 -129 -28 -130 -60 -130 -17 0 -30 3 -30 8 0
4 10 53 22 110 l21 102 34 0 c28 0 33 -3 30 -17z"/>
<path d="M1955 1243 c15 -26 14 -37 -18 -180 l-34 -153 111 0 c61 0 117 4 124
8 14 9 85 320 78 339 -4 10 -41 13 -141 13 l-136 0 16 -27z m160 -75 c-4 -18
-14 -67 -24 -108 -16 -74 -17 -75 -50 -78 -29 -3 -33 -1 -29 15 3 11 13 61 24
111 l18 92 34 0 c32 0 33 -1 27 -32z"/>
<path d="M2365 1238 c-7 -18 -41 -97 -74 -176 -34 -79 -61 -145 -61 -148 0 -2
20 -4 43 -4 41 0 45 2 62 39 17 39 17 40 -5 68 l-22 28 35 3 c33 3 36 7 57 63
21 56 49 84 36 37 -3 -13 -6 -44 -6 -70 l0 -47 -37 -3 -38 -3 38 -41 c20 -23
37 -49 37 -58 0 -12 10 -16 45 -16 l45 0 0 180 0 180 -71 0 c-70 0 -70 0 -84
-32z"/>
<path d="M2593 1252 c23 -25 22 -41 -13 -197 -16 -74 -30 -137 -30 -141 0 -3
19 -4 42 -2 l43 3 14 73 c8 39 18 72 22 72 4 0 11 -25 15 -55 l9 -56 35 26
c19 14 43 39 53 55 11 17 21 29 23 28 1 -2 -4 -32 -11 -68 -8 -36 -14 -68 -15
-72 0 -5 18 -8 39 -8 46 0 40 -13 80 170 16 74 32 147 36 163 7 26 6 27 -38
27 -45 0 -46 0 -93 -70 -26 -39 -50 -73 -55 -75 -4 -3 -10 12 -14 33 -3 20
-11 54 -16 75 l-10 37 -66 0 c-63 0 -65 -1 -50 -18z"/>
<path d="M3021 1233 c-29 -110 -30 -106 43 -158 36 -26 66 -50 66 -55 0 -27
-15 -40 -47 -40 -29 0 -33 3 -28 19 4 14 -3 24 -30 40 -20 11 -38 21 -39 21
-8 0 -27 -130 -21 -139 4 -7 49 -11 115 -11 l110 0 6 28 c3 15 10 43 15 62 18
71 13 79 -113 161 -21 13 5 39 39 39 26 0 34 -4 30 -14 -3 -8 9 -22 33 -35
l38 -21 13 54 c19 85 17 86 -110 86 l-110 0 -10 -37z"/>
<path d="M3229 588 c0 -2 -2 -20 -5 -40 -2 -21 -8 -58 -13 -82 -5 -25 -6 -48
-3 -51 21 -21 99 31 127 85 12 23 12 30 -1 48 -14 21 -101 53 -105 40z m79
-41 c6 -5 12 -16 12 -24 0 -17 -78 -99 -86 -90 -3 3 0 25 7 48 6 24 11 54 11
65 0 17 5 20 22 16 11 -3 27 -9 34 -15z"/>
<path d="M2750 556 c-42 -111 -30 -157 36 -140 35 9 30 27 -6 19 -37 -8 -38 0
-10 80 24 70 24 65 5 65 -9 0 -20 -11 -25 -24z"/>
<path d="M3047 573 c-11 -11 -8 -153 3 -153 6 0 10 11 10 25 0 25 14 33 25 15
3 -5 16 -15 28 -21 19 -11 18 -8 -6 21 -22 25 -25 35 -16 46 9 11 8 14 -5 14
-11 0 -16 9 -16 30 0 29 -9 38 -23 23z"/>
<path d="M3490 550 c0 -16 -5 -30 -11 -30 -8 0 -8 -4 -1 -13 6 -6 12 -28 14
-47 2 -27 8 -36 26 -38 12 -2 22 1 22 7 0 6 -6 10 -12 9 -8 -2 -14 9 -16 25
-3 22 1 30 17 34 12 3 21 9 21 14 0 5 -7 6 -15 3 -12 -5 -15 2 -15 30 0 25 -4
36 -15 36 -10 0 -15 -10 -15 -30z"/>
<path d="M4717 564 c-13 -46 -18 -114 -8 -114 15 0 37 108 24 121 -8 8 -13 6
-16 -7z"/>
<path d="M2189 533 l-12 -36 -28 34 c-18 23 -31 31 -38 24 -14 -14 -31 -128
-20 -139 14 -15 21 -1 27 49 l5 46 26 -25 c15 -14 30 -26 34 -26 9 0 22 32 32
78 9 45 -10 41 -26 -5z"/>
<path d="M3769 550 l-24 -19 38 10 c47 13 67 6 67 -21 0 -23 -45 -70 -66 -70
-7 0 -14 12 -16 27 -4 38 -28 42 -28 4 0 -56 64 -69 111 -22 39 39 39 76 -1
96 -38 20 -53 19 -81 -5z"/>
<path d="M4201 530 c-25 -45 -32 -45 -51 0 -7 16 -16 30 -21 30 -14 0 -69 -81
-69 -102 0 -29 17 -21 37 19 24 49 39 52 50 7 11 -49 24 -52 48 -12 l20 33 3
-37 c6 -76 25 -36 35 77 4 36 -28 27 -52 -15z"/>
<path d="M2560 480 c0 -38 4 -50 15 -50 8 0 15 7 15 16 0 9 14 29 31 45 31 29
25 38 -11 19 -13 -7 -19 -6 -24 5 -12 32 -26 13 -26 -35z"/>
<path d="M3905 518 c-9 -24 -2 -81 11 -85 6 -2 17 10 24 26 7 17 21 33 31 36
10 4 19 11 19 16 0 11 -7 11 -35 0 -14 -6 -24 -3 -32 8 -11 15 -12 15 -18 -1z"/>
<path d="M2845 510 c-4 -6 6 -10 22 -11 l28 -1 -27 -9 c-56 -21 -39 -75 20
-63 24 5 32 12 32 28 0 46 -56 87 -75 56z m45 -54 c0 -15 -24 -28 -34 -19 -7
7 13 33 25 33 5 0 9 -6 9 -14z"/>
<path d="M3385 510 c-4 -6 6 -10 22 -11 l28 0 -25 -10 c-64 -26 -48 -76 20
-62 22 4 30 11 30 27 0 46 -56 87 -75 56z m45 -54 c0 -15 -24 -28 -34 -19 -7
7 13 33 25 33 5 0 9 -6 9 -14z"/>
<path d="M3585 510 c-4 -6 6 -10 22 -11 l28 0 -25 -10 c-65 -26 -48 -76 21
-62 28 5 30 9 25 36 -3 17 -11 36 -17 44 -13 15 -45 17 -54 3z m45 -54 c0 -15
-24 -28 -34 -19 -7 7 13 33 25 33 5 0 9 -6 9 -14z"/>
<path d="M4386 512 c-3 -5 -8 -28 -12 -52 -6 -34 -4 -42 7 -38 7 2 16 15 19
29 8 34 40 38 40 5 0 -29 14 -35 24 -10 8 22 -11 54 -32 54 -10 0 -23 5 -29
11 -6 6 -13 7 -17 1z"/>
<path d="M4590 506 c0 -15 22 -42 39 -48 6 -2 7 -22 4 -46 -4 -32 -12 -46 -27
-52 -19 -8 -19 -9 6 -9 36 -1 48 23 48 98 0 59 -13 80 -27 44 -5 -14 -8 -13
-14 5 -9 25 -29 30 -29 8z"/>
<path d="M2241 492 c-18 -33 30 -79 69 -67 33 10 23 25 -12 19 l-33 -6 29 24
c17 15 26 30 22 36 -11 19 -64 14 -75 -6z m39 -7 c-7 -9 -15 -13 -17 -11 -7 7
7 26 19 26 6 0 6 -6 -2 -15z"/>
<path d="M2402 485 c-20 -25 -21 -25 -27 -5 -3 11 -13 20 -20 20 -11 0 -12 -6
-3 -32 6 -18 17 -39 24 -47 12 -13 17 -8 35 33 24 55 20 68 -9 31z"/>
<path d="M2457 503 c-29 -28 21 -91 63 -78 32 10 23 25 -12 19 l-33 -6 29 24
c17 15 26 30 22 36 -8 13 -58 16 -69 5z m43 -12 c0 -5 -7 -11 -15 -15 -15 -5
-20 5 -8 17 9 10 23 9 23 -2z"/>
<path d="M2944 496 c-3 -8 -4 -25 -2 -38 2 -19 9 -23 37 -23 20 0 36 6 39 13
3 11 0 12 -17 3 -12 -7 -26 -9 -31 -6 -17 11 -11 37 10 42 28 8 25 23 -5 23
-14 0 -28 -6 -31 -14z"/>
<path d="M4282 498 c-17 -17 -15 -43 3 -58 23 -19 65 3 65 34 0 33 -43 49 -68
24z m42 -31 c-3 -9 -10 -17 -15 -17 -11 0 -12 34 -2 43 11 12 24 -8 17 -26z"/>
<path d="M4491 496 c-25 -30 29 -84 69 -71 33 10 22 25 -12 18 l-33 -6 28 27
c15 14 25 31 22 36 -9 15 -61 12 -74 -4z m39 -11 c-7 -9 -15 -13 -17 -11 -7 7
7 26 19 26 6 0 6 -6 -2 -15z"/>
<path d="M1925 431 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M1975 431 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M2025 431 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M4690 431 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
